





























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ZoomImage extends Vue {
  @Prop({ required: true }) isShow;
  @Prop({ required: true }) imageName;

  close() {
    this.$emit("update:isShow", false);
    this.$emit("close", false);
  }
}

