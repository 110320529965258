import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { Trans } from "@/plugins/Translation";
import Home from "../views/home/Home.vue";
import Payment from "../views/payment/Payment.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/:locale",
    component: {
      template: "<router-view></router-view>",
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: "",
        name: "home",
        component: Home,
      },
      {
        path: "payment",
        name: "payment",
        component: Payment,
      },
    ],
  },
  {
    path: "*",
    redirect() {
      return Trans.defaultLocale;
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
