









































import { Component, Vue } from "vue-property-decorator";
import LottieComponent from "@/components/Lottie.vue";
import * as ukraineFlagData from "@/assets/lottie/ukraine-flag.json";
import {Trans} from "@/plugins/Translation";

@Component({
  components: {
    LottieComponent,
  },
})
export default class App extends Vue {
  anim: any = {};

  get currentLocale() {
    return Trans.currentLocale;
  }

  get supportedLocales() {
    return Trans.supportedLocales;
  }

  ukraineFlagOptions = {
    animationData: ukraineFlagData,
  };

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  changeLang(locale: string) {
    //@ts-ignore
    if (this.$i18n.locale !== locale) {
      Trans.changeLocale(locale)
      const to = this.$router.resolve({ params: {locale} });
      this.$router.push(to.location);
    }
  }
}
