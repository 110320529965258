
















































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import QrcodeVue from "qrcode.vue";
// @ts-ignore
import CopyToClipboard from "vue-copy-to-clipboard";

@Component({
  components: {
    QrcodeVue,
    CopyToClipboard,
  },
})
export default class Payment extends Vue {
  NICKNAME_STEP = 1;
  NETWORK_STEP = 2;
  ENTER_HASH_STEP = 3;
  STATUS_STEP = 4;

  public BINANCE_PAY = 1;
  public TRC20 = 2;
  public ERC20 = 3;
  public BEP20 = 4;
  public companyName: string = "";
  public hash: string = "";
  public currentStep: number = 1;
  public amount: number = 0;
  public qrLink: string = "";
  public showBinancePaySum = true;
  public allowNext: boolean = true;
  public successStatus: boolean = false;
  public intervalId: any;
  public qrLinkLoaded: boolean = false;

  TRC20_ADDRESS: string = process.env.VUE_APP_TRC20_ADDRESS;
  ERC20_ADDRESS: string = process.env.VUE_APP_ERC20_ADDRESS;
  BEP20_ADDRESS: string = process.env.VUE_APP_BEP20_ADDRESS;

  public tabs = [
    // { id: this.BINANCE_PAY, title: "Binance Pay", active: true },
    { id: this.TRC20, title: "TRC20", active: true },
    { id: this.ERC20, title: "ERC20", active: false },
    { id: this.BEP20, title: "BEP20", active: false },
  ];

  currentTab = this.tabs[0];

  changePaymentMethod(tab: any) {
    this.tabs.forEach((item) => (item.active = false));
    tab.active = true;
    this.currentTab = tab;
  }

  qrLinkLoadedAction() {
    this.qrLinkLoaded = true;
    this.allowNext = true;
  }

  async generateBinancePayQr() {
    this.allowNext = false;
    this.qrLinkLoaded = false;
    const { data: res } = await axios.post(
      `${process.env.VUE_APP_API_ENDPOINT}/ua-fundraising/create/bpay`,
      {
        username: this.companyName,
        amount: this.amount,
      }
    );
    this.qrLink = res.qrcode;
    localStorage.setItem("txId", res.txId);
  }

  async manualPay() {
    localStorage.setItem("txId", this.hash);
    try {
      await axios.post(
        `${process.env.VUE_APP_API_ENDPOINT}/ua-fundraising/create/manual`,
        {
          username: this.companyName,
          txId: this.hash,
        }
      );
    } catch (e) {
      console.error(e);
    }
  }

  nextStep() {
    if (this.currentStep >= 4) {
      return;
    }
    if (
      this.currentStep === this.NETWORK_STEP &&
      this.currentTab.id === this.BINANCE_PAY
    ) {
      if (!this.showBinancePaySum) {
        this.currentStep = this.STATUS_STEP;
        this.updateStatus();
        return;
      }
      this.generateBinancePayQr();
      this.showBinancePaySum = false;
      return;
    }

    if (this.currentStep === this.ENTER_HASH_STEP && this.hash.length > 6) {
      this.manualPay();
    }

    this.currentStep += 1;

    if (this.currentStep === this.STATUS_STEP) {
      this.updateStatus();
    }
  }

  updateStatus() {
    this.successStatus = false;

    let txId: string | null;
    if (this.hash) {
      txId = this.hash;
    } else {
      txId = localStorage.getItem("txId");
    }
    if (txId == null) {
      return;
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(async () => {
      const { data: res } = await axios.get(
        `${process.env.VUE_APP_API_ENDPOINT}/ua-fundraising/status`,
        {
          params: { txId },
        }
      );
      if (res.status) {
        clearInterval(this.intervalId);
        this.successStatus = true;
        setTimeout(
          () => this.$router.push(`/${this.$route.params.locale}`),
          2500
        );
      }
    }, 5000);
  }

  prevStep() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    if (this.currentStep === 1) {
      return this.$router.push({ name: "home" });
    }

    if (!this.showBinancePaySum) {
      this.currentStep = this.NETWORK_STEP;
      this.showBinancePaySum = true;
      return;
    }

    if (this.currentStep <= 1) {
      return;
    }

    this.currentStep -= 1;
  }

  get nextButtonDisabled() {
    if (
      this.currentStep === this.NETWORK_STEP &&
      this.currentTab.id === this.BINANCE_PAY &&
      this.amount <= 0
    ) {
      return true;
    }

    if (this.currentStep === this.NICKNAME_STEP && !this.companyName) {
      return true;
    }
    if (this.currentStep === this.ENTER_HASH_STEP && this.hash.length < 6) {
      return true;
    }

    return false;
  }
}
