
























































































































































































































































import { Component, Vue } from "vue-property-decorator";
import VueSlickCarousel from "vue-slick-carousel";
import ZoomImage from "@/components/zoom-image/ZoomImage.vue";
import axios from "axios";
import { Trans } from "@/plugins/Translation";

@Component({
  components: { VueSlickCarousel, ZoomImage }
})
export default class Home extends Vue {
  public sum: string = '0';
  public donationsLoaded: boolean = false;
  public showZoomModal: boolean = false;
  public imageName: string = '';

  public sliderSettings = {
    "infinite": false,
    "slidesToShow": 4,
    "speed": 500,
    "rows": 2,
    "dots": true,
    "arrows": false,
    "swipe": false,
    "slidesPerRow": 1,
    "responsive": [
      {
        "breakpoint": 1280,
        "settings": {
          "slidesToShow": 3,
        }
      },
      {
        "breakpoint": 768,
        "settings": {
          "slidesToShow": 2,
        }
      }
    ]
  }

  openZoomModal(event) {
    console.log(event.currentTarget);

    this.showZoomModal = true;
    this.imageName = event.currentTarget.querySelector('img').dataset.name;
  }

  closeZoomModal() {
    this.showZoomModal = false;
  }

  public nextSlide() {
    //@ts-ignore
    this.$refs.slider.next();
  }

  public prevSlide() {
    //@ts-ignore
    this.$refs.slider.prev();
  }

  get link() {
    return `/${Trans.currentLocale}/payment`;
  }

  public async getStats() {
    const { data: res } = await axios.get(
        `${process.env.VUE_APP_API_ENDPOINT}/ua-fundraising/stats`
    );
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    this.sum = formatter.format(res.sum);
    this.donationsLoaded = true;
  }

  async mounted() {
    await this.getStats();
  }
}
